<template>
	<div class="container">
		<div class="d-flex justify-content-between align-items-center mb-4">
			<h3 class="h3 mb-0">課程名稱管理</h3>
			<button class="btn btn-info flex-shrink-0" @click="showEditModal()">新增課程</button>
		</div>

		<div class="d-flex mb-4" style="width: 400px">
			<input
				type="text"
				class="form-control me-3"
				placeholder="課程名稱"
				v-model="searchKeyword"
				@keyup.enter="getList()"
			/>
			<button class="flex-shrink-0 btn btn-primary" @click="getList()">查詢</button>
		</div>

		<table class="table mb-5">
			<thead class="bg-light">
				<tr>
					<th>課程名稱</th>
					<th>建立日期</th>
					<th class="text-center">上課次數</th>
					<th class="text-center">啟用狀態</th>
					<th class="text-center" style="width: 50px"></th>
					<th class="text-center" style="width: 50px"></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="item in pageList" :key="item.id">
					<td title="課程名稱">{{ item.lesson_name }}</td>
					<td title="建立日期">{{ formatDatetime(item.updated_at) }}</td>
					<td title="上課次數" class="text-center">{{ item.count }}</td>
					<td class="text-center">
						<span v-if="item.status == 0" class="text-secondary">停用</span>
						<span v-else-if="item.status == 1">啟用</span>
					</td>
					<td class="text-center">
						<a class="link-primary" role="button" @click="showEditModal(item.id)">編輯</a>
					</td>
					<td class="text-center">
						<a v-if="item.role_id !== 1" class="link-danger" role="button" @click="deleteData(item.id)"
							>刪除</a
						>
					</td>
				</tr>
			</tbody>
		</table>

		<Pagination
			v-model="pagination.currentPage"
			:records="pagination.records"
			:per-page="pagination.perPage"
			:options="pagination.options"
			@paginate="getPageList"
		/>

		<div id="editModal" class="modal fade" tabindex="-1">
			<div class="modal-dialog modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">{{ editID >= 0 ? "編輯" : "新增" }}課程</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<Vueform
							ref="editForm$"
							:schema="editFormSchema"
							:display-errors="false"
							validate-on="step"
						></Vueform>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
						<button type="button" class="btn btn-primary" @click="editSubmit()">確定</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import { apiLesson, apiLessonChangeStatus } from "@/assets/js/api.js";
import { textEllipsis, formatDatetime } from "@/assets/js/common.js";
import { ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { Modal } from "bootstrap";

export default {
	name: "Lesson",
	setup() {
		const store = useStore();

		let dataList = [];
		const pageList = ref([]);
		const searchKeyword = ref("");

		const pagination = reactive({
			currentPage: 1,
			records: 0,
			perPage: 20,
			options: {
				theme: "bootstrap4",
				hideCount: true,
			},
		});

		let editModal = {};
		const editID = ref(-1); // -1: add ; >= 0: edit
		const editForm$ = ref(null);
		const editFormSchema = ref({
			lesson_name: {
				label: "課程名稱",
				type: "text",
				rules: ["required", "min:2", "max:60"],
				default: "",
			},
			status: {
				text: "停用",
				type: "toggle",
				trueValue: 0,
				falseValue: 1,
				default: 1,
			},
		});

		const setPageLoading = (isLoading) => store.dispatch("setPageLoading", isLoading);

		const getList = () => {
			setPageLoading(true);

			apiLesson({
				method: "get",
				data: {
					text: searchKeyword.value,
				},
			})
				.then((response) => {
					dataList = response.data.data;
					pagination.currentPage = 1;
					pagination.records = response.data.data.length;
					getPageList(1);

					setPageLoading(false);
				})
				.catch((error) => {
					setPageLoading(false);
					console.error(error);
					Swal.fire({
						icon: "error",
						text: "CODE ERROR",
					});
				});
		};

		const getPageList = (currentPage) => {
			let startIndex = (currentPage - 1) * pagination.perPage;
			let endIndex = startIndex + pagination.perPage;

			pageList.value = dataList.slice(startIndex, endIndex);
		};

		const getDetail = (id) => {
			return new Promise((resolve, reject) => {
				setPageLoading(true);

				apiLesson({
					method: "get",
					id,
				})
					.then((response) => {
						setPageLoading(false);
						if (response.data.status == "0") {
							resolve(response.data.data.lesson);
						} else {
							reject();
						}
					})
					.catch(() => {
						setPageLoading(false);
						reject();
					});
			});
		};

		const showEditModal = async (id) => {
			editID.value = -1;
			editForm$.value.reset();

			// edit
			if (id) {
				let detailData = await getDetail(id);
				editID.value = detailData.id;
				editForm$.value.load(detailData);
			}

			setTimeout(() => {
				editForm$.value.resetValidators();
				editModal.show();
			}, 100);
		};

		const editSubmit = () => {
			const data = editForm$.value.data;
			editForm$.value.validate().then(() => {
				if (!editForm$.value.invalid) {
					setPageLoading(true);

					let apiOptions = {
						method: "",
						data,
					};

					if (editID.value < 0) {
						//add
						apiOptions.method = "post";
					} else {
						//edit
						apiOptions.method = "put";
						apiOptions.id = editID.value;
					}

					apiLesson(apiOptions)
						.then((response) => {
							setPageLoading(false);
							if (response.data.status == "0") {
								Swal.fire({
									icon: "success",
									text: response.data.message,
								});
								getList();
								editModal.hide();
							} else {
								Swal.fire({
									icon: "warning",
									text: response.data.message,
								});
							}
						})
						.catch(() => {
							setPageLoading(false);
						});
				}
			});
		};

		const deleteData = (id) => {
			Swal.fire({
				icon: "question",
				text: "確定要刪除嗎?",
				showCancelButton: true,
				confirmButtonText: "確定",
				cancelButtonText: "取消",
			}).then((result) => {
				if (result.isConfirmed) {
					setPageLoading(true);

					apiLesson({
						method: "delete",
						id,
					})
						.then((response) => {
							setPageLoading(false);
							if (response.data.status == "0") {
								Swal.fire({
									icon: "success",
									text: response.data.message,
								});
								getList();
							} else {
								Swal.fire({
									icon: "warning",
									text: response.data.message,
								});
							}
						})
						.catch((error) => {
							setPageLoading(false);
						});
				}
			});
		};

		onMounted(() => {
			editModal = new Modal(document.getElementById("editModal"));
			getList();
		});

		return {
			searchKeyword,
			pageList,
			pagination,
			editForm$,
			editID,
			editFormSchema,
			getList,
			getPageList,
			showEditModal,
			editSubmit,
			deleteData,
			textEllipsis,
			formatDatetime,
		};
	},
};
</script>
